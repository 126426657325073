.panel {
    @apply w-full bg-white rounded-sm shadow-lg;

    .panel-header {
        @apply h-14 flex items-center;

        .panel-toggler {
            @apply w-14 h-full flex items-center shrink-0 justify-center border-r border-gray-300 hover:cursor-pointer;

            .panel-toggler-icon {
                @apply h-6 w-6 text-gray-900;
            }
        }

        .panel-header-right {
            @apply w-full flex justify-between items-center pl-4 pr-6  select-none;

            .panel-title-container {
                @apply flex items-center;
            }

            .panel-title {
                @apply text-base leading-6 font-bold;
            }

            .connected-bubble {
                @apply flex justify-center items-center text-white text-xs ml-1 rounded-full bg-gray-900;
                width: 18px;
                height: 18px;
            }

            .button-container {
                @apply w-8 h-8 bg-red-50;

                button {
                    @apply w-full h-full p-0;

                    .edy-optic-button-icon-container {
                        @apply h-5 w-5;

                        svg {
                            @apply w-full h-full;
                        }
                    }
                }
            }
        }
    }

    .panel-content {
        @apply h-80 p-2 border-t border-gray-300;
    }

    .panel-footer {
        @apply h-14 flex justify-center items-center px-6 py-4 border-t border-gray-300;
    }
}
