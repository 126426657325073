.add-folder-modal-form-container {
    @apply space-y-6;

    .choose-parent-folder-container {
        .choose-parent-folder-label {
            @apply text-sm leading-5 font-medium text-input-label mb-1;
        }
    }

    .disclaimer-text {
        @apply text-error;
        padding: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
    }
}
