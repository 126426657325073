.article-card-container {
    @apply w-full flex flex-col sm:flex-row rounded-sm bg-white;

    .article-left {
        @apply w-full p-8 pb-0 sm:pb-8;

        .mobile-article-image {
            @apply block sm:hidden h-48 mx-auto mb-4;
        }

        .date {
            @apply text-primary text-sm leading-5 font-medium;
        }

        .title {
            @apply title-xl;
        }

        .content {
            @apply text-body-sm text-gray-500 my-2 sm:mt-3 sm:mb-10 line-clamp-5;
        }

        .resources-container {
            @apply w-full flex justify-between p-3 rounded-sm bg-gray-100;

            .resources-left {
                @apply flex gap-2;

                .file-name-container {
                    @apply flex items-center gap-1 text-primary px-3 border-r border-r-gray-300;

                    &:first-child {
                        @apply pl-0;
                    }

                    &:last-child {
                        @apply pr-0 border-r-0;
                    }

                    .icon-container {
                        @apply w-4;
                    }
                }

                .file-name {
                    @apply text-xs text-ellipsis overflow-hidden whitespace-nowrap;
                    max-width: 150px;
                }
            }

            .remaining-resources {
                @apply shrink-0 text-xs text-gray-500 leading-4;

                span {
                    @apply px-1 text-white font-semibold rounded-full bg-primary mx-1;
                    font-size: 11px;
                }
            }
        }
    }

    .article-right {
        @apply w-full sm:w-72 p-4 sm:p-8 flex flex-col items-center justify-between gap-2;

        .article-image-container {
            @apply h-48 hidden sm:flex items-end bg-cover bg-no-repeat bg-center;

            width: 136px;
        }
    }
}
