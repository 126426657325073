.edy-icon-selector-container {
    .icons-container {
        @apply w-full h-80 mt-3 py-2 px-3 border-y border-gray-border  overflow-y-auto;

        .no-icons-text {
            @apply text-gray-500 text-sm font-medium;
        }

        .icons-list {
            @apply w-full max-h-80 grid place-items-center grid-cols-3 sm:grid-cols-6 gap-x-2 gap-y-2;

            .icon-card-container {
                @apply w-full grid grid-rows-2 place-items-center p-2 border border-gray-border rounded hover:cursor-pointer hover:bg-gray-50;
                min-height: 5rem;

                &.selected {
                    @apply bg-gray-border;

                    .icon-name {
                        @apply font-bold;
                    }
                }

                .icon-image {
                    @apply w-8 max-h-8;
                }

                .icon-name {
                    @apply text-xs text-center;
                }
            }
        }
    }

    .loader-container {
        @apply h-80 grid place-items-center;
    }
}
