.user-actions-container {
    @apply relative p-1 hover:bg-gray-background rounded-md;

    .user-actions-button {
        .user-component-container {
            @apply flex items-center gap-2;

            .user-icon-container {
                @apply w-8 h-8 flex items-center justify-center rounded-full border border-primary;

                .user-icon {
                    @apply h-6 w-6 text-primary;
                }
            }

            .user-texts-container {
                .user-name-container {
                    @apply flex items-center text-primary hover:text-primary-dark hover:cursor-pointer;
                    gap: 6px;

                    .user-name {
                        @apply hidden sm:block text-sm leading-5 font-semibold  whitespace-nowrap;
                    }

                    .chevron-down-icon {
                        @apply h-5 w-5;
                    }
                }

                .user-role {
                    @apply hidden sm:block text-left text-xs text-gray-500;
                }
            }
        }
    }

    .user-action-items-container {
        @apply absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;

        .user-texts-container {
            @apply block sm:hidden p-2;

            .user-name-container {
                @apply flex items-center text-primary hover:text-primary-dark hover:cursor-pointer;
                gap: 6px;

                .user-name {
                    @apply text-sm leading-5 font-semibold  whitespace-nowrap;
                }
            }

            .user-role {
                @apply text-left text-xs text-gray-500;
            }
        }

        .user-action-item {
            @apply w-full flex items-center text-gray-900 text-sm rounded-md px-2 py-2;

            &.active {
                @apply bg-primary text-white;
            }

            .user-action-item-icon {
                @apply w-5 h-5 mr-2 text-gray-900;

                &.active {
                    @apply text-white;
                }
            }
        }
    }
}
