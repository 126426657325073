.my-cart-container {
    @apply flex items-center gap-2 p-1 hover:bg-gray-background rounded-md;

    .shoppintg-cart-icon-container {
        @apply w-8 h-8 flex items-center justify-center rounded-full border border-primary;

        .shopping-cart-icon {
            @apply w-5 h-5 text-primary;
        }
    }

    .texts-container {
        .my-cart-text-container {
            @apply flex items-center gap-1;

            .my-cart-text {
                @apply hidden sm:block text-sm text-primary leading-5 font-semibold;
            }

            .cart-items-container {
                @apply flex items-center justify-center bg-primary rounded-full;
                height: 14px;
                width: 14px;

                .cart-items-text {
                    @apply text-white font-extrabold;
                    font-size: 10px;
                }
            }
        }

        .price-container {
            @apply hidden sm:block;

            .price-text {
                @apply text-xs leading-4 text-gray-500;
            }
        }
    }
}
