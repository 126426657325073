@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border font-sans;
}

*:focus {
  @apply outline-none;
}

html {
  @apply scroll-smooth;
}

body {
  min-width: 320px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply m-0 p-0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

