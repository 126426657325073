.edy-optic-textarea-label-container {
    label.edy-optic-label {
        @apply text-sm leading-5 font-medium text-input-label mb-1;
    }

    .edy-optic-textarea-wrapper {
        @apply relative text-gray-600 focus-within:text-gray-900;

        .input-icon-wrapper {
            @apply absolute w-5 inset-y-0 left-4 flex items-center pointer-events-none;

            & > * {
                @apply h-full;
            }
        }

        .currency-text-wrapper,
        .discount-icon-wrapper {
            @apply absolute flex items-center inset-y-0 right-4 text-gray-500 pointer-events-none;
        }

        textarea.edy-optic-textarea {
            @apply appearance-none block border border-input-border rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;

            &::placeholder {
                @apply text-sm leading-5 font-normal text-input-placeholder;
            }

            &[type="number"]::-webkit-inner-spin-button,
            &[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &.medium {
            textarea.edy-optic-textarea {
                @apply text-sm;
                padding: 0.5rem 14px;

                &.has-icon {
                    @apply pl-12 pr-3;
                }

                &.is-price,
                &.is-discount {
                    @apply pr-14;
                }
            }
        }

        &.large {
            textarea.edy-optic-textarea {
                @apply px-4 py-3 text-base;

                &.has-icon {
                    @apply pl-12 pr-4;
                }

                &.is-price,
                &.is-discount {
                    @apply pr-14;
                }
            }
        }
    }

    &.disabled {
        .edy-optic-textarea-wrapper {
            @apply bg-input-disabled-background;

            textarea.edy-optic-textarea {
                @apply border-input-disabled-background bg-input-disabled-background text-input-disabled-text;
            }
        }
    }

    &.full-width {
        @apply w-full;

        textarea.edy-optic-textarea {
            @apply w-full;
        }
    }
}
