.product-images-modal {
    @apply w-full max-w-full sm:max-w-7xl relative;

    .mobile-close-modal-button {
        @apply sm:hidden self-end absolute top-1 right-2;
    }

    .product-images-modal-container {
        @apply flex flex-col sm:flex-row gap-2;

        @media (min-width: 640px) {
            max-height: 100%;
            min-height: 100%;
        }

        .loader-container {
            @apply w-full flex items-center justify-center;
        }

        .product-images-modal-image-container {
            @apply w-full;

            .products-images-slider-container {
                @apply w-full h-full;
            }
        }
    }
}
