.user-categories-page-container {
    @apply h-content flex;

    .user-categories-page-content-container {
        @apply w-full overflow-y-auto bg-gray-background;

        .user-categories-page-header-container {
            @apply flex justify-between items-center pr-2 sm:pr-8;

            .add-button {
                @apply h-7 sm:h-auto w-7 sm:w-auto p-0;

                @media (min-width: 640px) {
                    padding: 10px 1.25rem;
                }

                .edy-optic-button-title {
                    @apply hidden sm:block;
                }

                .edy-optic-button-icon-container {
                    @apply h-3 sm:h-8 w-3 sm:w-8;

                    &.has-title {
                        @apply mr-0 sm:mr-3;
                    }
                }
            }
        }

        .user-categories-page-content {
            @apply w-full overflow-x-auto md:overflow-x-visible;
        }

        .datatable-brand-name {
            @apply text-gray-900 text-base leading-6 font-semibold;
        }

        .brand-logo-container {
            @apply w-14 h-14 p-3 border rounded-sm border-gray-300;
        }

        .category-badges-container {
            @apply flex flex-wrap gap-1;
        }
    }
}

.buttons-row {
    @apply flex gap-2;
}
