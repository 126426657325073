.edy-optic-number-input-label-container {
    label.edy-optic-label {
        @apply text-sm leading-5 font-medium text-input-label mb-1;
    }

    .edy-optic-number-input-container {
        @apply flex flex-row w-full rounded-sm relative bg-transparent border border-input-border;

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input.edy-optic-number-input {
            @apply w-full flex items-center text-sm leading-5 text-center text-primary border-0 border-transparent outline-none focus:outline-none focus:ring-0 focus:ring-offset-0;
        }

        #edy-optic-number-input-button {
            @apply focus:ring-0 focus:ring-offset-0;
        }
    }

    &.full-width {
        @apply w-full;
    }
}
