.brand-resource-card-container {
    @apply w-full max-w-full sm:max-w-[320px] shrink-0 rounded-sm border border-primary-light bg-center bg-no-repeat bg-cover;
    aspect-ratio: 2/3;
    background-image: url("../../../../assets/images/background-blue.svg");

    .top {
        @apply border-b-0 bg-white p-4 z-10 bg-top bg-no-repeat bg-cover;
        height: calc(100% - 5rem);

        .brand-image {
            @apply h-14 w-14 z-20;
        }
    }

    .bottom {
        @apply h-20 flex items-center gap-2 justify-between px-5 border-t-0 bg-primary;

        .texts-container {
            @apply max-w-full text-white overflow-hidden;

            .helper-text {
                @apply text-body-xs;
            }

            .brand-name-text {
                @apply title-3xl text-ellipsis overflow-hidden whitespace-nowrap;
            }
        }

        .resource-link {
            @apply h-10 w-10 shrink-0 grid place-items-center bg-primary-light;

            .link-icon {
                @apply h-6 text-white;
            }
        }
    }

    &:hover {
        .bottom {
            @apply bg-primary-dark;
        }
    }

    &.shop-card-container {
        .top {
            @apply grid place-items-center bg-center bg-no-repeat bg-cover;
            background-image: url("../../../../assets/images/shop.jpg");

            .shop-icon {
                @apply w-1/2 md:w-3/4 text-white stroke-1;
            }
        }
    }
}

@media (min-width: 2000px) {
    .brand-resource-card-container {
        width: 100%;
        max-width: 450px;

        .top {
            .brand-image {
                @apply h-14 w-14 z-20;
            }
        }

        .bottom {
            @apply h-28 flex items-center gap-2 justify-between px-5;

            .texts-container {
                .helper-text {
                    @apply text-base;
                }

                .brand-name-text {
                    @apply title-4xl;
                }
            }

            .resource-link {
                @apply h-14 w-14;

                .link-icon {
                    @apply h-10;
                }
            }
        }
    }
}
