.brand-news-page-container {
    @apply h-content flex;

    .brand-news-page-left {
        @apply w-full h-content bg-gray-100;

        .search-container {
            @apply h-14 px-8;
        }

        .brand-news-container {
            @apply w-full flex flex-col gap-4 overflow-y-auto px-8 pb-10;

            height: calc(theme("height.content") - theme("height.header") - theme("height.14"));

            .no-news-container {
                .no-news-text {
                    @apply text-gray-500 font-medium;
                }

                .add-news-link {
                    @apply text-primary font-semibold underline;
                }
            }

            .loader-container {
                @apply py-5 grid place-items-center;
            }
        }
    }

    .brand-news-page-right {
        @apply h-content hidden md:block w-0 md:w-1/3;

        max-width: 400px;
        min-width: 300px;
    }
}
