.cart-page-container {
    @apply flex flex-col sm:flex-row justify-between sm:h-content;

    .cart-page-left {
        @apply w-full sm:h-content sm:pb-20 bg-gray-100;

        .products-container {
            @apply flex flex-col gap-2 py-4 px-8 overflow-y-auto;

            .discount-disclaimer {
                @apply py-3 px-5 mb-3 rounded text-primary bg-blue-100;

                span {
                    @apply font-bold;
                }
            }

            .brand-name {
                @apply title-2xl mb-2 mt-4 border-b border-gray-border;
            }

            .no-products-container {
                @apply flex flex-col items-center gap-4 py-5;

                .no-products-text {
                    @apply text-sm font-medium text-gray-500;
                }
            }
        }
    }

    .cart-page-right {
        @apply sm:h-content sm:w-1/3 flex flex-col justify-between p-8;

        .cart-page-right-top {
            .section-title {
                @apply title-xl mb-6;
            }

            .total-products {
                @apply text-gray-500 text-sm leading-5 font-normal border-b border-gray-300 pb-2 mb-1;
            }

            .prices-container {
                .price-section-row {
                    @apply flex justify-between;

                    .total {
                        @apply text-primary text-base leading-6 font-bold mb-5 sm:mb-0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .cart-page-container {
        .cart-page-left {
            .products-container {
                height: calc(theme("height.content") - theme("height.header"));
            }
        }

        .cart-page-right {
            max-width: 400px;
        }
    }
}
