.choose-products-modal-container {
    @apply space-y-5;
    height: calc(32rem - 8px);

    .products-list {
        @apply flex flex-col gap-2 max-h-96 h-96 overflow-y-auto px-3;

        .product-card-container {
            @apply flex justify-between py-2 px-3 bg-white border border-gray-300 rounded-sm hover:cursor-pointer hover:bg-gray-100;

            &.selected {
                @apply bg-primary hover:bg-primary-light;

                .product-info-container {
                    @apply w-full flex gap-4 items-center;

                    .product-image {
                        @apply bg-white;
                    }

                    .product-texts-container {
                        .product-name {
                            @apply w-full text-white text-xs leading-4 font-semibold;
                        }

                        .product-category {
                            @apply text-gray-100;
                            font-size: 10px;
                        }
                    }
                }
            }

            .product-info-container {
                @apply w-full flex gap-4 items-center;

                .product-image {
                    @apply w-auto h-20;
                }

                .product-texts-container {
                    .product-name {
                        @apply w-full text-primary text-xs leading-4 font-semibold select-none;
                    }

                    .product-category {
                        @apply text-gray-500 select-none;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .loader-container {
        @apply w-full grid place-items-center max-h-96 h-96;
    }
}
