.Toastify__toast-container.Toastify__toast-container--top-center {
    @apply max-w-full;
    width: 650px;

    .view-cart-toast-container {
        @apply flex gap-10 justify-between items-center;

        .view-cart-toast-left-container {
            @apply flex items-center gap-4;

            .product-image-container {
                width: 72px;
                height: 72px;

                @apply flex justify-center items-center;

                img {
                    @apply max-h-full;
                }
            }

            .product-name-container {
                @apply flex flex-col justify-center;

                .product-name {
                    @apply text-primary text-xl font-bold leading-5;
                }

                .secondary-text {
                    @apply text-sm;
                }
            }
        }

        .view-cart-toast-right-container {
            @apply flex gap-7 items-center;

            .total-price-container {
                @apply hidden xs:block;

                .total-cart {
                    @apply text-sm  leading-3;
                }

                .total-price {
                    @apply text-primary font-bold whitespace-nowrap;
                }
            }

            .buttons-container {
                @apply flex gap-1 items-center;

                .view-cart-button {
                    @apply hidden sm:flex;
                }
            }
        }
    }
}
