.page-header-container {
    @apply h-header-sm md:h-header flex flex-col justify-center px-8;

    .page-header-left {
        @apply flex items-center py-2 sm:py-9;

        .page-title {
            @apply title-2xl sm:title-4xl ml-2 sm:ml-5 pl-2 sm:pl-5 border-l border-gray-300;
            line-height: 1;
        }

        .back-button {
            padding: 0 !important;

            @apply h-7 sm:h-10 w-7 sm:w-10;
            min-width: 1.75rem;

            .edy-optic-button-icon-container {
                @apply h-3 sm:h-8 w-3 sm:w-8;
            }
        }
    }
}
