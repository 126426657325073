.edy-optic-article-dropzone {
    @apply w-full flex justify-between items-center border-2 border-dashed border-gray-500 rounded-md hover:cursor-default bg-gray-50;
    padding: 6px 1rem;

    .article-dropzone-left {
        @apply flex items-center gap-4;

        .upload-icon {
            @apply w-6 text-primary;
        }

        .texts-container {
            .dropzone-label {
                @apply text-gray-900 text-base leading-6 font-semibold;
            }

            .secondary-text {
                @apply text-gray-400 text-xs leading-4;
            }
        }
    }

    .edy-optic-article-dropzone-image-preview {
        @apply flex items-center gap-3;

        .preview-image {
            @apply w-11 h-11;
        }

        .texts-container {
            .preview-label {
                @apply text-xs leading-4 font-normal text-gray-500;
            }

            .image-file-name {
                @apply text-sm leading-5 font-semibold text-primary;
            }
        }
    }
}
