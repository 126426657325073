.edy-optic-sidebar-container {
    @apply w-14 sm:w-24 lg:w-52 xl:w-60 2xl:w-72 shrink-0 px-0 py-2 sm:p-4 space-y-3 lg:space-y-1 bg-primary;

    .edy-optic-sidebar-link-container {
        @apply w-full flex justify-center sm:justify-between items-center py-2 sm:py-3 lg:py-1 px-2;

        .edy-optic-sidebar-link-left {
            @apply flex items-center gap-4;

            .edy-optic-sidebar-link-icon {
                @apply h-5 sm:h-7 lg:h-5 text-white;

                svg {
                    @apply w-full h-full;
                }
            }

            .edy-optic-sidebar-link-title {
                @apply hidden lg:block text-white text-sm leading-8 font-medium whitespace-nowrap;
            }
        }

        &.selected {
            @apply bg-primary-light;
        }
    }
    .edy-optic-sidebar-link-arrow-icon {
        @apply w-0 sm:w-4 h-0 sm:h-4 text-gray-300;
    }
}

@media (min-width: 1024px) {
    .edy-optic-sidebar-container {
        min-width: 230px;
    }
}
