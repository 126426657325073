#login-container {
    @apply min-h-screen flex;

    .login-left {
        @apply flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24;

        .title {
            @apply mt-6 text-2xl font-extrabold text-gray-900;
        }

        .password-input-container {
            @apply flex flex-col items-end gap-1;
        }

        .need-account {
            @apply text-body-sm;

            .contact-link {
                @apply font-medium text-primary;
            }
        }

        .password-actions-container {
            @apply flex items-center justify-between;

            .forgot-password-link {
                @apply text-sm font-medium text-primary hover:text-primary-dark;
            }
        }
    }

    .login-right {
        background-image: url("../../assets/images/background-blue.svg");
        background-repeat: no-repeat;
        background-size: cover;

        @apply hidden lg:flex flex-col items-center justify-center relative w-0 flex-1;

        .edy-optic-logo {
            // @apply h-11 max-w-full;
            @apply h-20 max-w-full;
        }

        .app-indicator-image {
            @apply mt-7 h-7;
        }
    }
}
