.add-resource-page-container {
    @apply h-content flex;

    .add-resource-page-content-container {
        @apply w-full h-full overflow-y-auto bg-gray-background;

        .page-header-container {
            @apply bg-white;
        }

        .no-brands-text {
            @apply p-10 text-lg text-gray-500 font-medium;

            .add-brand-link {
                @apply text-primary font-bold underline;
            }
        }

        .add-resource-form {
            @apply space-y-4 p-10;

            .add-resource-card {
                @apply bg-white;

                .add-resource-card-header-container {
                    @apply flex items-center justify-between py-4 px-6 border-b border-gray-border;

                    .header-left {
                        @apply flex items-center gap-3;

                        .add-resource-card-number-container {
                            @apply w-6 h-6 flex items-center justify-center bg-gray-100 rounded-sm border border-primary;

                            .add-resource-card-number {
                                @apply text-xl leading-7 font-bold text-primary;
                            }
                        }

                        .add-resource-card-title {
                            @apply text-gray-900 text-xl leading-7 font-semibold;
                        }
                    }
                }

                .add-resource-card-content-container {
                    @apply py-8 px-6;

                    .select-category-text {
                        @apply text-gray-500;
                    }

                    .current-folder-container {
                        @apply flex flex-col items-start gap-5;

                        .current-folder-text {
                            span {
                                @apply font-bold;
                            }
                        }
                    }

                    .add-resource-card-content-row {
                        @apply grid grid-cols-2 gap-3;

                        & > * {
                            min-width: 200px;
                        }
                    }
                }

                .add-resource-date-card-content-container {
                    @apply grid grid-cols-3;

                    .left {
                        @apply py-8 px-6 space-y-4 border-r border-gray-border;
                    }

                    .right {
                        @apply grid grid-cols-2 col-span-2 gap-3 place-items-start py-8 px-6;

                        & > * {
                            @apply w-full;
                        }

                        .loader-container {
                            @apply col-span-2;
                        }
                    }
                }
            }
        }
    }
}