.add-brand-page-container {
    @apply h-content flex;

    .add-brand-page-content-container {
        @apply w-full h-full overflow-y-auto bg-white;

        .page-header-container {
            @apply bg-white;
        }

        .add-brand-form-container {
            @apply flex-grow flex flex-col gap-y-4 p-8 pt-2;

            .add-brand-form-row {
                @apply flex gap-x-4 flex-grow;

                > * {
                    width: calc(50% - .5rem);
                }
            }
        }
    }
}
