.products-page-container {
    @apply h-content flex;

    .products-page-content-container {
        @apply w-full overflow-y-auto bg-gray-background;

        .products-page-header-container {
            @apply flex justify-between items-center pr-2 sm:pr-8;

            .add-link-button {
                button {
                    @apply h-7 sm:h-auto w-7 sm:w-auto p-0;

                    @media (min-width: 640px) {
                        padding: 10px 1.25rem;
                    }

                    .edy-optic-button-title {
                        @apply hidden sm:block;
                    }

                    .edy-optic-button-icon-container {
                        @apply h-3 sm:h-8 w-3 sm:w-8;

                        &.has-title {
                            @apply mr-0 sm:mr-3;
                        }
                    }
                }
            }
        }

        .filters-container {
            @apply flex flex-col md:flex-row gap-4 pb-4 px-8 border-b border-gray-300;

            .filters-left {
                @apply md:w-1/2;
            }

            .filters-right {
                @apply md:w-1/2 flex gap-4;

                > * {
                    @apply w-full;
                }
            }
        }

        .products-page-content {
            @apply w-full overflow-x-auto md:overflow-x-visible;
        }

        .datatable-product-cell {
            @apply flex gap-3 items-center;

            min-width: 200px;

            .product-image {
                @apply h-16;
            }
        }

        .brand-logo-container {
            @apply w-14 h-14 p-3 border rounded-sm border-gray-300;
        }

        .category-badges-container {
            @apply flex flex-wrap gap-1;
        }
    }
}

.buttons-row {
    @apply flex gap-2;
}
