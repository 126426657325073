.add-news-container {
    @apply flex h-content;

    .add-news-left {
        @apply w-full h-content flex flex-col overflow-y-auto;

        .add-news-form-container {
            @apply flex flex-col flex-grow shrink-0 gap-y-4 p-8 pt-2;

            .add-news-form-row {
                @apply flex gap-4;

                > * {
                    @apply w-full;
                }
            }

            .news-content-editor-container {
                @apply flex-grow;

                .ck.ck-editor {
                    @apply h-full flex flex-col;

                    .ck.ck-editor__main {
                        @apply flex-grow;

                        .ck.ck-content {
                            @apply h-full;
                        }
                    }
                }
            }
        }
    }

    .add-news-right {
        @apply h-content w-1/3 flex flex-col justify-between py-4 px-8 gap-2 bg-gray-100;
        min-width: 380px;
        max-width: 450px;

        .panels-container {
            @apply flex flex-col gap-2;
        }

        .loader-container {
            @apply h-full flex justify-center;
        }
    }
}

.connected-resources-container {
    @apply h-full flex flex-col gap-1 overflow-auto;

    .resource-card-container {
        @apply flex justify-between py-2 px-3 bg-gray-100 rounded-sm;

        .texts-container {
            .resource-name-container {
                @apply flex items-center gap-1;

                .icon-container {
                    @apply h-3;

                    .document-icon {
                        @apply w-full h-full text-primary;
                    }
                }

                .resouce-name {
                    @apply text-primary text-xs leading-4 font-semibold;
                }
            }

            .secondary-texts {
                @apply flex text-gray-500;
                font-size: 10px;
            }
        }
    }
}
