.brand-product-categories-container {
    .brand-product-categories-header {
        @apply w-full h-28 flex items-center justify-between px-8;

        background-image: url("../../assets/images/background-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .left {
            @apply flex items-center gap-5;

            .brand-name {
                @apply title-2xl sm:title-3xl text-white border-l pl-5;
                border-color: rgba($color: #fff, $alpha: 0.3);
            }

            .back-button {
                padding: 0 !important;

                @apply h-10 w-10;

                .edy-optic-button-icon-container {
                    @apply w-8 h-8;
                }
            }
        }
        .brand-logo-container {
            @apply h-10 sm:h-16 w-10 sm:w-16 flex items-center justify-center rounded bg-white;

            img {
                @apply w-6 sm:w-9 h-6 sm:h-9;
            }
        }
    }

    .resources-container-outter {
        @apply bg-gray-background overflow-auto;

        height: calc(theme("height.content") - theme("height.28"));

        .resources-container {
            @apply grid grid-cols-4 sm:grid-cols-8 lg:grid-cols-12 gap-2 p-7;

            .brand-resource-container {
                @apply flex flex-col items-center py-10 justify-center bg-white hover:cursor-pointer hover:bg-gray-50 col-span-4 md:col-span-2;

                &.has-image {
                    @apply p-0;
                }

                .category-image {
                    @apply w-full h-full;
                }

                .brand-resource-icon-container,
                .category-icon {
                    @apply h-12 text-gray-600;

                    img {
                        @apply h-full;
                    }
                }

                .brand-resource-title-container {
                    @apply w-2/3 h-12 flex flex-col items-center justify-center;

                    .brand-resource-title {
                        @apply w-full text-primary text-center capitalize font-semibold line-clamp-2;
                    }
                }

                .brand-resource-arrow {
                    @apply h-4 w-4 text-primary;
                }
            }

            .loader-container {
                @apply w-full col-span-4 sm:col-span-8 lg:col-span-12;
            }
        }
    }
}
