.navbar-container {
    @apply w-full flex items-center justify-between px-8 bg-white shadow border-b border-gray-background z-10;
    height: 72px;

    .left,
    .right {
        @apply flex items-center;
    }

    .left {
        .logo-container {
            // @apply w-24 xs:w-44 h-auto xs:h-5;
            @apply w-24 h-11;

            img {
                max-height: 100%;
            }
        }
        

        .app-indicator-image {
            @apply ml-3 h-0 md:h-5;
        }
    }

    .right {
        @apply gap-8;
    }
}

@media print {
    .navbar-container {
        @apply hidden;
    }
}
