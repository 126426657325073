.home-container {
    @apply h-full flex flex-col justify-start items-center;

    .title-container {
        @apply flex items-center gap-2 my-5;

        .home-title {
            @apply title-2xl text-center;
        }
    }

    .links-container {
        @apply w-full flex flex-col md:flex-row items-center md:items-start justify-center gap-2 px-8 pt-8 pb-5 md:pb-0;

        .brands-container {
            @apply w-full flex flex-col md:flex-row items-center gap-2 overflow-y-hidden overflow-x-auto md:overflow-x-visible;
        }
    }

    .shop-folder-container {
        @apply w-full flex flex-col md:flex-row items-center md:items-start justify-center gap-6 px-8 pt-5 pb-5;

        .personal-folder-link, .shop-link {
            @apply w-full sm:w-1/2 md:w-1/3 min-w-[300px] max-w-[600px] flex justify-between items-center bg-primary hover:bg-primary-dark border border-gray-200 py-6 px-5 lg:px-8;

            .left {
                @apply flex items-center gap-5;

                .left-icon {
                    @apply block w-6 lg:w-8 text-white;
                }

                .link-title {
                    @apply text-sm md:text-base font-semibold text-white;
                    line-height: 18px;
                }
            }

            .link-icon-container {
                @apply h-10 w-10 shrink-0 grid place-items-center bg-primary-light;

                .link-icon {
                    @apply h-6 text-white;
                }
            }
        }
    }

    .admin-section-container,
    .placeholder-div {
        @apply w-full;
        min-height: 8rem;

        .admin-section-inner {
            @apply md:h-full w-full flex flex-wrap md:flex-nowrap items-center bg-gray-background py-5 px-8;

            .admin-option-container {
                @apply w-full sm:w-1/2 md:w-1/4 flex justify-between items-center bg-white hover:bg-gray-100 border-r border-gray-200 py-6 px-5 lg:px-8;

                .left {
                    @apply flex items-center gap-5;

                    .admin-option-icon {
                        @apply block w-6 lg:w-8 text-gray-400;
                    }

                    .admin-option-title {
                        @apply text-sm md:text-base font-semibold text-primary;
                        line-height: 18px;
                    }
                }

                .options-icon {
                    @apply block md:hidden lg:block w-5 h-5 text-primary;
                }
            }
        }
    }
}

@media (min-width: 1760px) { 
    .home-container { 
        .links-container {     
            .brands-container { 
                @apply w-auto;
                min-width: 1696px;
            } 
        } 
    } 
}

@media (min-width: 2000px) {
    .home-container {
        .title-container {
            .home-title {
                @apply title-4xl text-center;
            }
        }

        .links-container {     
            .brands-container { 
                @apply w-full; 
            } 
        } 

        .admin-section-container,
        .placeholder-div {
            @apply w-full;
            min-height: 10rem;

            .admin-section-inner {
                @apply md:h-full w-full flex flex-wrap md:flex-nowrap items-center bg-gray-background py-5 px-8;

                .admin-option-container {
                    .left {
                        .admin-option-icon {
                            @apply w-10;
                        }

                        .admin-option-title {
                            @apply text-xl;
                            line-height: 20px;
                        }
                    }

                    .options-icon {
                        @apply w-8 h-8;
                    }
                }
            }
        }
    }
}

@media (min-width: 2430px) { 
    .home-container { 
        .links-container {     
            .brands-container { 
                @apply w-auto;
                min-width: 2350px;
            } 
        } 
    } 
}