.order-details-page-container {
    @apply w-full sm:h-content flex flex-col items-center overflow-y-auto bg-gray-background pb-5;

    .orders-page-header-container {
        @apply w-full flex justify-between items-center pr-2 sm:pr-8 mb-5 shadow-sm;
    }

    .order-details-container {
        @apply w-full max-w-5xl p-8 shrink-0 bg-white;

        .order-details-header {
            @apply flex flex-col sm:flex-row justify-between pb-5 border-b border-gray-border;

            .order-info {
                .logo-container {
                    @apply mb-5;

                    img {
                        // @apply h-7;
                        @apply h-11;
                    }
                }

                .order-number {
                    @apply pt-4 sm:pt-0;
                }

                .order-number,
                .order-date {
                    @apply text-lg font-medium;

                    span {
                        @apply font-normal;
                    }
                }

                .order-status-desktop {
                    @apply flex print:hidden items-center gap-2;

                    .order-status {
                        @apply text-lg font-medium;
                    }
                }

                .order-status-admin {
                    @apply block print:hidden text-lg font-medium;

                    span {
                        @apply font-normal;
                    }
                }

                .order-status-print {
                    @apply hidden print:block text-lg font-medium;

                    span {
                        @apply font-normal;
                    }
                }
            }

            .recipient-info {
                @apply w-full sm:w-1/3 pt-4 sm:pt-0;

                .invoice-to {
                    @apply text-xl font-bold;
                }

                .recipient-name {
                    @apply text-lg font-medium;
                }
            }
        }

        .order-details-content {
            @apply pt-5;

            .order-products-table {
                @apply w-full;

                th,
                td {
                    &.name-column {
                        @apply px-0 sm:px-2 text-center sm:text-left;
                    }
                }

                thead {
                    tr {
                        @apply bg-primary;

                        th {
                            @apply text-center text-white py-2 text-sm sm:text-base z-20;
                        }
                    }
                }

                tbody {
                    tr {
                        @apply border-b border-gray-border;

                        td {
                            @apply text-center py-2 text-sm sm:text-base;

                            &:nth-child(odd) {
                                @apply bg-gray-background;
                            }
                        }
                    }
                }
            }
        }
    }

    .order-details-footer {
        @apply flex justify-end pt-10;

        .order-price-container {
            @apply w-full sm:w-1/3;

            .sub-total,
            .discount {
                @apply text-lg font-medium;
            }

            .total {
                @apply text-primary text-xl font-bold mt-3 pt-3 border-t-2 border-gray-border;
            }
        }
    }
}

@media print {
    @page {
        size: auto;
        margin: 5mm;
        orientation: portrait;
    }

    .order-details-page-container {
        @apply bg-white pb-0;

        -ms-overflow-style: none; /* IE and Edge */

        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .orders-page-header-container {
            @apply hidden;
        }

        .order-details-container {
            @apply w-screen h-screen;

            .print-button {
                @apply hidden;
            }
        }
    }
}
