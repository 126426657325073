.product-card-container {
    @apply flex flex-col sm:flex-row shadow-lg rounded-sm bg-white;

    .product-image-container {
        @apply relative max-h-72 sm:h-full sm:w-72 lg:w-72 flex flex-col justify-center items-center px-8 py-10;

        .view-images-button {
            @apply absolute top-1 right-11 shadow-none p-2;

            .edy-optic-button-icon-container {
                @apply w-6;
            }
        }

        img {
            @apply max-h-full;
        }
    }

    .product-info {
        @apply w-full p-6;

        .product-name {
            @apply title-2xl text-primary hover:cursor-pointer hover:underline;
            line-height: 1.75rem;
        }

        .product-description {
            @apply my-2 sm:mt-4 sm:mb-8 line-clamp-3;
        }

        .product-card-footer {
            @apply w-full flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between pt-6 border-t border-gray-300;

            .product-price-container {
                @apply text-gray-900;

                .product-price {
                    @apply text-xl leading-7 font-semibold;
                }

                .product-minimum-ammount {
                    @apply leading-4;
                    font-size: 10px;
                }
            }

            .buttons-container {
                @apply flex justify-between xl:justify-start items-center mt-2 xl:mt-0 gap-2;

                .avo-quantity-input {
                    max-width: 80px;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem; 
                }

                .view-details-button {
                    .edy-optic-button-title {
                        @apply hidden xs:block;
                    }

                    .edy-optic-button-icon-container {
                        @apply ml-0 xs:ml-3 lg:hidden xl:block;
                    }
                }
            }
        }
    }
}
