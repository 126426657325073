.product-details-modal {
    @apply w-full max-w-full sm:max-w-7xl;

    .mobile-close-modal-button {
        @apply sm:hidden self-end;
    }

    .product-details-modal-container {
        @apply flex flex-col sm:flex-row gap-2;

        @media (min-width: 640px) {
            max-height: 100%;
            min-height: 100%;
        }

        .loader-container {
            @apply w-full flex items-center justify-center;
        }

        .product-details-modal-image-container {
            @apply w-full sm:w-4/12;

            .products-images-slider-container {
                @apply w-full h-full;
            }
        }

        .product-details-modal-content-container {
            @apply w-full sm:w-5/12 flex flex-col justify-between;

            .product-details-modal-header {
                @apply flex items-start justify-between;

                .header-texts {
                    .product-name {
                        @apply title-4xl;
                        line-height: 2.5rem;
                        margin-bottom: 0.5rem;
                    }

                    .product-brand-info {
                        @apply flex gap-2;

                        .product-brand,
                        .product-category {
                            @apply text-sm text-gray-500;
                        }
                    }
                }

                .close-modal-button {
                    @apply hidden sm:flex;
                }
            }

            .product-description {
                @apply h-full overflow-auto my-7 text-body-sm;
            }

            .product-details-modal-footer {
                @apply w-full flex justify-between py-6 sm:pt-6 border-y sm:border-t border-gray-300;

                .product-price-container {
                    @apply text-gray-900;

                    .product-price {
                        @apply text-xl leading-7 font-semibold;
                    }

                    .product-minimum-ammount {
                        @apply leading-4;
                        font-size: 10px;
                    }
                }

                .buttons-container {
                    @apply flex gap-2;

                    .avo-quantity-input {
                        max-width: 80px;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                    }
                }
            }
        }

        .product-details-modal-connected-resources-container {
            @apply w-full sm:w-3/12 px-2 sm:border-l sm:border-gray-border overflow-y-auto;
            max-height: 100%;
            overflow-y: auto;

            .connected-resources-section-title {
                @apply title-xl pb-2;
            }

            .connected-resources-list-container {
                @apply flex flex-col gap-1 px-0 md:px-6 overflow-y-auto;
                max-height: calc(100% - 38px);

                .resource-row {
                    @apply w-full flex justify-between items-center gap-2 py-2 border-b border-b-gray-border;

                    .resource-row-left {
                        @apply w-4/5;

                        .resource-name-cotnainer {
                            @apply flex gap-1 items-center;

                            .resource-icon {
                                @apply h-4 text-primary;
                            }

                            .resource-name {
                                @apply text-ellipsis overflow-hidden whitespace-nowrap text-sm font-bold text-primary;
                            }
                        }

                        .resource-location {
                            @apply text-xs text-gray-500;
                        }
                    }
                }
            }

            .no-resources {
                @apply text-sm text-gray-500 font-medium p-6;
            }
        }
    }
}
