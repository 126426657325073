.edy-optic-modal-container {
    @apply relative inline-block align-bottom bg-white rounded  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full text-left;

    .edy-optic-modal-header {
        @apply flex items-center justify-between px-4 pt-5 pb-4 sm:p-6 border-b border-gray-300;

        .edy-optic-modal-title {
            @apply text-2xl font-bold text-gray-900;
        }

        .edy-optic-modal-close-button-container {
            > .edy-optic-button {
                @apply w-8 h-8 p-0;

                .edy-optic-modal-close-button-icon {
                    @apply w-8 h-8 text-white;
                }
            }
        }
    }

    .edy-optic-modal-content-container {
        @apply px-4 pt-5 pb-4 sm:p-6;
        max-height: calc(100vh - 2rem);
        height: calc(100vh - 2rem);
        overflow-y: auto;

        @media (min-width: 640px) {
            max-height: calc(100vh - 4rem);
            height: calc(100vh - 4rem);
        }
        
    }
}
