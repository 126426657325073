.news-page-container {
    @apply h-content bg-gray-100;

    .news-page-header-container {
        @apply flex justify-between items-center pr-2 sm:pr-8;

        .add-button {
            @apply h-7 sm:h-auto w-7 sm:w-auto p-0;

            @media (min-width: 640px) {
                padding: 10px 1.25rem;
            }

            .edy-optic-button-title {
                @apply hidden sm:block;
            }

            .edy-optic-button-icon-container {
                @apply h-3 sm:h-8 w-3 sm:w-8;

                &.has-title {
                    @apply mr-0 sm:mr-3;
                }
            }
        }
    }

    .news-page-content {
        @apply w-full overflow-x-auto md:overflow-x-visible;
    }

    .no-news-container {
        @apply px-4;

        .no-news-text {
            @apply text-gray-500 font-medium;
        }

        .add-news-link {
            @apply text-primary font-semibold underline;
        }
    }
}

.news-actions-column-container {
    @apply flex gap-2;
}
