.brand-details-container {
    .brand-details-header {
        @apply w-full h-16 md:h-28 flex items-center justify-between px-8;

        background-image: url("../../assets/images/background-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .left {
            @apply flex items-center gap-5;

            .brand-name {
                @apply title-2xl sm:title-3xl text-white border-l pl-5;
                border-color: rgba($color: #fff, $alpha: 0.3);
            }

            .back-button {
                padding: 0 !important;

                @apply h-10 w-10;

                .edy-optic-button-icon-container {
                    @apply w-8 h-8;
                }
            }
        }

        .brand-logo-container {
            @apply h-10 sm:h-16 w-10 sm:w-16 flex items-center justify-center rounded bg-white;

            img {
                @apply w-6 sm:w-9 h-6 sm:h-9;
            }
        }
    }

    .resources-container-outter {
        @apply bg-gray-background overflow-auto;

        height: calc(theme("height.content") - theme("height.28"));

        .resources-container {
            @apply grid grid-cols-2 sm:grid-cols-8 lg:grid-cols-8 gap-2 p-7;
            justify-items: center;

            .brand-resource-container {
                @apply h-24 lg:h-32 flex flex-row justify-center items-center row-span-1 col-span-2 sm:col-span-4 lg:col-span-3 bg-white py-10 hover:cursor-pointer hover:bg-gray-50;

                .brand-resource-icon-container,
                .category-icon {
                    @apply h-12 text-gray-600 mr-3;

                    img {
                        @apply h-full;
                    }
                }

                .brand-resource-title-container {
                    @apply max-w-xs w-auto h-12 flex flex-col items-center justify-center text-ellipsis;

                    .brand-resource-title {
                        @apply w-full text-primary text-center capitalize font-semibold line-clamp-2;
                    }
                }

                .brand-resource-arrow {
                    @apply h-4 w-4 ml-4 text-primary;
                }
            }

            .new-brand-resource-container {
                @apply flex flex-col justify-center items-center row-span-1 col-span-2 sm:col-span-4 lg:col-span-2 hover:cursor-pointer bg-white hover:bg-gray-50 w-full;
                min-height: 165px;

                .category-image {
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                }

                .brand-resource-icon-container,
                .category-icon {
                    @apply h-12 text-gray-600;

                    img {
                        @apply h-full;
                    }
                }

                .brand-resource-title-container {
                    @apply max-w-xs w-auto h-12 flex flex-col items-center justify-center text-ellipsis;

                    .brand-resource-title {
                        @apply w-full text-primary text-center capitalize font-semibold line-clamp-2;
                    }
                }

                .brand-resource-arrow {
                    @apply h-4 w-4 text-primary;
                }
            }
        }
    }
}
