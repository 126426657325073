.load-more-component-container,
.loading-container {
    @apply pt-5;
}

.load-more-component-container {
    @apply flex justify-center items-center;

    &::before,
    &::after {
        content: "";
        height: 1px;

        @apply w-full mx-5 bg-gray-border;
    }
}

.loading-container {
    @apply flex gap-2 justify-center items-center;

    .loading-text {
        @apply text-gray-500 font-medium;
    }
}
