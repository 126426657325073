.recent-resources-container {
    @apply h-content w-full flex flex-col bg-white p-4 lg:py-7 lg:px-8;

    .section-title {
        @apply title-xl mb-6;
    }

    .resources-container {
        @apply flex flex-col overflow-y-auto;
        gap: 6px;

        .no-resources {
            @apply text-sm text-gray-500;
        }
    }
}

.resource-card-container {
    @apply flex justify-between py-2 px-3 bg-gray-100 rounded-sm;

    .texts-container {
        .resource-name-container {
            @apply flex items-center gap-1;

            .icon-container {
                @apply h-3;

                .document-icon {
                    @apply w-full h-full text-primary;
                }
            }

            .resouce-name {
                @apply w-40 text-ellipsis overflow-hidden whitespace-nowrap text-primary text-xs leading-4 font-semibold;
            }
        }

        .secondary-texts {
            @apply flex text-gray-500;
            font-size: 10px;
            padding-top: 2px;
            padding-bottom: 2px;

            *:nth-child(2) {
                @apply ml-2 pl-2 border-l border-gray-300;
            }
        }
    }
}
