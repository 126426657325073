button.edy-optic-button {
    @apply flex justify-center items-center border border-transparent rounded-sm  font-medium whitespace-nowrap;

    &.contained {
        @apply shadow-sm;

        &.primary {
            @apply text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
        }

        &.primary-light {
            @apply text-white bg-primary-light hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light;
        }

        &.secondary {
            @apply text-primary bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50;
        }

        &.white {
            @apply text-primary bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100;
        }

        &.red {
            @apply text-red-500 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-50;
        }

        &:disabled {
            @apply text-gray-400 bg-gray-100 hover:cursor-default hover:bg-gray-400 hover:text-gray-100;
        }
    }

    &.text {
        &.primary {
            @apply bg-transparent text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
        }

        &.primary-light {
            @apply bg-transparent text-primary-light hover:text-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light;
        }

        &.secondary {
            @apply bg-transparent text-blue-50 hover:text-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50;
        }

        &.gray {
            @apply bg-transparent text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white;
        }

        &.red {
            @apply bg-transparent text-red-500 hover:text-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
        }

        &:disabled {
            @apply text-gray-300 hover:text-gray-300 hover:cursor-default;
        }
    }

    &.outlined {
        @apply shadow-sm;

        &.primary {
            @apply border border-primary hover:border-primary-dark bg-transparent text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
        }

        &.primary-light {
            @apply border border-primary-light hover:border-primary bg-transparent text-primary-light hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light;
        }

        &.secondary {
            @apply border border-blue-50 hover:border-blue-100 bg-transparent text-blue-50 hover:text-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50;
        }

        &.white {
            @apply border border-white hover:border-gray-100  bg-transparent text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white;
        }

        &.red {
            @apply border border-red-500 hover:border-red-100 bg-transparent text-red-500 hover:text-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
        }

        &:disabled {
            @apply border-gray-300 hover:border-gray-300 text-gray-300 hover:text-gray-300  hover:cursor-default;
        }
    }

    .edy-optic-button-icon-container {
        @apply flex justify-center items-center;

        &.has-title {
            @apply ml-3;
        }

        svg {
            @apply h-full w-full;
        }
    }

    &.icon-left {
        @apply flex-row-reverse;

        .edy-optic-button-icon-container.has-title {
            @apply ml-0 mr-3;
        }
    }

    &.small {
        @apply text-xs leading-4 py-1 px-3;

        .edy-optic-button-icon-container {
            @apply w-4;
        }
    }

    &.medium {
        @apply text-sm leading-4 py-2 px-4;

        .edy-optic-button-icon-container {
            @apply w-4;
        }
    }

    &.large {
        @apply text-sm leading-5;
        padding: 10px 1.25rem;

        .edy-optic-button-icon-container {
            @apply w-5;
        }
    }

    &.extra-large {
        @apply text-base leading-6 py-3 px-6;

        .edy-optic-button-icon-container {
            @apply w-6;
        }
    }

    &.full-width {
        width: 100% !important;
    }
}
