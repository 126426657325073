.cart-product-card-container {
    @apply flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0 rounded-sm p-4 bg-white;

    .cart-product-card-left {
        @apply flex items-center gap-5;

        .product-image-container {
            @apply flex justify-center items-center border border-gray-300 p-1;
            width: 72px;
            height: 72px;

            img {
                @apply max-h-full;
            }
        }

        .product-info {
            .product-name {
                @apply text-lg leading-7 font-bold;
            }
        }
    }

    .cart-product-card-right {
        @apply w-full lg:w-1/2 flex flex-col xs:flex-row justify-between items-center;

        & > * {
            @apply w-full xs:w-auto;
        }

        .avo-quantity-input {
            max-width: 80px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .price-container {
            @apply flex flex-row items-center gap-2 py-2 xs:block;

            .price-type {
                @apply text-gray-400 text-xs leading-none;
            }

            .old-price {
                @apply line-through text-xs;
            }

            .price {
                @apply text-sm leading-5 font-semibold;
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .cart-product-card-container {
        .cart-product-card-right {
            min-width: 300px;
        }
    }
}
