.article-page-container {
    @apply w-full min-h-content;

    .article-image {
        @apply w-full h-full bg-cover bg-center bg-no-repeat;
    }

    .article-content-container {
        @apply w-full flex lg:justify-between flex-col lg:flex-row overflow-auto py-10 lg:pt-16;

        .article-container {
            @apply w-full h-full overflow-y-auto px-6 xs:px-12;

            .buttons-container {
                @apply w-full flex gap-2 py-1 items-center justify-between;

                .right-buttons-container {
                    @apply flex gap-2 items-center;

                    .edy-optic-button-title {
                        @apply hidden md:block;
                    }

                    .edy-optic-button-icon-container {
                        @apply mr-0 md:mr-3;
                    }

                    .admin-button {
                        .edy-optic-button-title {
                            @apply hidden md:block lg:hidden xl:block;
                        }

                        .edy-optic-button-icon-container {
                            @apply mr-0 md:mr-3 lg:mr-0 xl:mr-3;
                        }
                    }
                }
            }

            .article-info {
                .article-info-row {
                    @apply flex gap-2 pt-3 text-xs sm:text-sm text-gray-500 italic font-semibold divide-x divide-gray-300;

                    .article-brand {
                        @apply pl-3;
                    }
                }
            }

            .article-title-row {
                @apply flex items-center justify-between;

                .article-title {
                    @apply title-2xl sm:title-4xl;
                }

                .view-resources-button {
                    @apply flex lg:hidden;
                }
            }

            .article-content {
                @apply text-body-xs sm:text-body-sm text-gray-900 pb-0 lg:pb-16;
            }
        }

        .article-connected-resources {
            @apply w-2/5 hidden lg:block shrink-0 pr-6 xl:pr-12 pb-0 lg:pb-16;

            min-width: 400px;

            .article-connected-resources-card {
                @apply max-h-full flex flex-col py-8 border border-gray-border bg-white;

                .connected-resources-list-container {
                    @apply flex flex-col gap-1 mt-8 px-6 max-h-full overflow-y-auto;

                    .resource-row {
                        @apply w-full flex justify-between items-center gap-2 py-2 border-b border-b-gray-border;

                        .resource-row-left {
                            @apply w-full;

                            .resource-name-cotnainer {
                                @apply flex gap-1 items-center;

                                .resource-icon {
                                    @apply h-4 text-primary;
                                }

                                .resource-name {
                                    @apply text-ellipsis overflow-hidden whitespace-nowrap text-sm font-bold text-primary;
                                }
                            }

                            .resource-location {
                                @apply text-xs text-gray-500;
                            }
                        }
                    }
                }

                .no-resources {
                    @apply text-sm text-gray-500 font-medium p-6;
                }
            }
        }

        .article-connected-products-card {
            @apply max-h-full flex flex-col py-8 mt-4 border border-gray-border bg-white;

            .connected-products-list-container {
                @apply flex flex-col gap-1 mt-8 px-6 max-h-full overflow-y-auto;

                .product-row {
                    @apply w-full flex justify-between items-center gap-2 py-2 border-b border-b-gray-border;

                    .product-info-container {
                        @apply flex items-center gap-4;

                        .product-image {
                            @apply h-12;
                        }

                        .product-texts {
                            .product-name {
                                @apply text-ellipsis overflow-hidden whitespace-nowrap text-sm font-bold text-primary;
                            }

                            .product-category {
                                @apply text-xs text-gray-500;
                            }
                        }
                    }
                }
            }

            .no-products {
                @apply text-sm text-gray-500 font-medium p-6;
            }
        }
    }
}

// @media print {
//     @page {
//         size: auto;
//         margin: 5mm;
//         orientation: portrait;
//     }

//     .article-page-container {
//         @apply block w-full min-h-full pb-10;

//         .article-image {
//             @apply block mt-5;

//             page-break-inside: avoid;
//         }

//         .article-content-container {
//             @apply w-auto h-auto px-12;

//             .article-container {
//                 .buttons-container {
//                     @apply hidden;
//                 }

//                 .article-info {
//                     .article-info-row {
//                         @apply flex gap-2 pt-3 text-sm text-gray-500 italic font-semibold divide-x divide-gray-300;

//                         .article-brand {
//                             @apply pl-3;
//                         }
//                     }
//                 }

//                 .article-title-row {
//                     .article-title {
//                         @apply title-4xl;
//                     }

//                     .view-resources-button-container {
//                         .view-resources-button {
//                             @apply hidden;
//                         }
//                     }
//                 }

//                 .article-content {
//                     @apply text-body-sm text-gray-900;
//                 }
//             }
//         }

//         .article-connected-resources {
//             @apply hidden;
//         }
//     }
// }
