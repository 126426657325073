.choose-resources-modal-container {
    @apply space-y-5;
    height: calc(32rem - 8px);

    .resources-list {
        @apply flex flex-col gap-2 max-h-96 h-96 overflow-y-auto px-3;

        .resource-card-container {
            @apply flex justify-between py-2 px-3 bg-white border border-gray-300 rounded-sm hover:cursor-pointer hover:bg-gray-100;

            &.selected {
                @apply bg-primary hover:bg-primary-light;

                .texts-container {
                    .resource-name-container {
                        .icon-container {
                            .document-icon {
                                @apply text-white;
                            }
                        }

                        .resouce-name {
                            @apply text-white select-none;
                        }
                    }

                    .secondary-texts {
                        @apply text-gray-100 select-none;
                    }
                }
            }

            .texts-container {
                .resource-name-container {
                    @apply flex items-center gap-1;

                    .icon-container {
                        @apply h-3 w-3;

                        .document-icon {
                            @apply w-full h-full text-primary;
                        }
                    }

                    .resouce-name {
                        @apply w-full text-primary text-xs leading-4 font-semibold;
                    }
                }

                .secondary-texts {
                    @apply flex text-gray-500;
                    font-size: 10px;
                }
            }
        }
    }

    .loader-container {
        @apply w-full grid place-items-center max-h-96 h-96;
    }
}
