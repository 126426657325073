.add-product-container {
    @apply flex h-content;

    .add-product-left {
        @apply w-full h-content flex flex-col overflow-y-auto;

        .add-product-form-container {
            @apply flex-grow flex flex-col gap-y-4 p-8 pt-2;

            .add-product-form-row {
                @apply flex gap-x-4 flex-grow;

                > * {
                    width: calc(50% - .5rem);
                }

                .product-description-editor-container {
                    @apply flex-grow;

                    .ck.ck-editor {
                        @apply h-full flex flex-col;
                        min-height: 200px;

                        .ck.ck-editor__main {
                            @apply flex-grow;

                            .ck.ck-content {
                                @apply h-full;
                            }
                        }
                    }
                }

                &.features-container {
                    @apply w-full flex-col;

                    .features-header {
                        @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border w-full;

                        .features-title {
                            @apply title-xl;
                        }
                    }

                    .features-list-container {
                        @apply w-full flex flex-col gap-y-2;

                        .feature-card-container {
                            @apply w-full flex items-center gap-2;
                        }
                    }
                }

                &.tags-container {
                    @apply w-full flex-col;

                    .tags-header {
                        @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border w-full;

                        .tags-title {
                            @apply title-xl;
                        }
                    }

                    .tags-list-container {
                        @apply w-full flex gap-2 flex-wrap;

                        .tag-card-container {
                            @apply flex items-center gap-2;
                            width: calc(50% - 0.5rem);
                        }
                    }
                }

                &.images-container {
                    @apply w-full flex-col;

                    .images-header {
                        @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border w-full;

                        .images-title {
                            @apply title-xl;
                        }
                    }

                }

                &.video-container {
                    @apply w-full flex-col;

                    .video-header {
                        @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border w-full;

                        .video-title {
                            @apply title-xl;
                        }
                    }

                    .video-list-container {
                        @apply w-full flex flex-col gap-y-4;

                        .video-card-container {
                            @apply w-full flex items-center gap-2 bg-gray-50;
                        }
                    }
                }

                &.benefits-container {
                    @apply w-full flex-col;

                    .benefits-header {
                        @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border w-full;

                        .benefits-title {
                            @apply title-xl;
                        }
                    }

                    .benefits-description {
                        @apply w-full mb-4;
                    }

                    .benefits-list-container {
                        @apply w-full flex flex-col gap-y-4;

                        .benefits-card-container {
                            @apply w-full flex items-center gap-2 bg-gray-50;
                        }
                    }
                }

                .description-header {
                    @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border w-full;

                    .description-title {
                        @apply title-xl;
                    }
                }
            }
        }
    }

    .add-product-right {
        @apply h-content w-1/3 py-4 px-8 bg-gray-100;
        min-width: 380px;
        max-width: 450px;

        .add-product-right-form-container {
            @apply h-full flex flex-col justify-between rounded-sm p-4 bg-white shadow-lg overflow-auto;

            .form-inputs-container {
                @apply space-y-6;

                .price-container {
                    @apply flex flex-col gap-2 space-y-1;
                }

                .resources-section-container {
                    .resources-section-header {
                        @apply flex justify-between items-center pb-2 mb-2 border-b border-gray-border;

                        .resources-section-title {
                            @apply title-xl;
                        }
                    }

                    .connected-resources-container {
                        @apply max-h-72;
                    }
                }
            }
        }
    }
}
